<template>
  <div class="card-container">
    <card-shipping-header
      :expanded="expanded"
      :window-from="shipping['window_from']"
      :window-to="shipping['window_to']"
      @expand="expanded = !expanded"
    />
    <div class="w-100p">
      <div v-for="option in preparedListOption.slice(0, 3)" :key="option.key">
        <card-option
          v-if="option.value"
          :field="option.key"
          :type="option.type"
          :title="option.title"
          :description="
            option.key === 'num_auto'
              ? option.value.toUpperCase()
              : option.value
          "
        />
      </div>
      <transition name="slide-fade">
        <div v-if="expanded" class="w-100p">
          <div v-for="option in preparedListOption.slice(3)" :key="option.key">
            <card-option
              v-if="option.value"
              :key="option.key"
              :type="option.type"
              :title="option.title"
              :description="
                option.key === 'num_auto'
                  ? option.value.toUpperCase()
                  : option.value
              "
            />
          </div>
          <div class="df jc-sb ai-c p-r-16 p-l-16 m-t-16">
            <iq-mobile-button
              v-if="showDeleteButton"
              button-style="padding: 12px; width: 100%; margin-right: 4px; background: #DB6D39; border-color: #DB6D39; color: white"
              text-style="color: white; font-weight: 500"
              title="Удалить"
              @onClick="$emit('emitDelete', shipping)"
            />
            <iq-mobile-button
              button-style="padding: 12px; width: 100%; margin-left: 4px"
              text-style="color: #000000; font-weight: 500"
              title="Редактировать"
              @onClick="$emit('emitEdit', shipping)"
            />
          </div>
        </div>
      </transition>
    </div>
    <modal-fullscreen-mobile />
  </div>
</template>
<script>
import { getArrRange } from '@/core'
import CardOption from '@/views/manager/components/mobile/CardOption'
import CardShippingHeader from '@/views/manager/components/mobile/CardShippingHeader'
import IqMobileButton from '@/UI/form/mobileButton/IqMobileButton'
import ModalFullscreenMobile from '@/views/manager/components/mobile/components/modal/ModalFullscreenMobile'
export default {
  name: 'CardShipping',
  components: {
    CardShippingHeader,
    CardOption,
    ModalFullscreenMobile,
    IqMobileButton,
  },
  props: {
    shipping: { type: Object, default: () => {} },
  },
  data() {
    return {
      expanded: false,
      isModalVisible: false,
      preparedListOption: [
        {
          value: null,
          key: 'num_auto',
          title: 'Номер авто',
          type: 'text',
        },
        {
          value: null,
          key: 'culture_name',
          title: 'Культура',
          type: 'text',
        },
        {
          value: null,
          key: 'truck_status',
          title: 'Статус',
          type: 'status',
        },
        {
          value: null,
          key: 'buffer_time',
          title: 'Заезд в буфер',
          type: 'text',
        },
        {
          value: null,
          key: 'culture_harvest_year',
          title: 'Год культуры',
          type: 'text',
        },

        {
          value: null,
          key: 'driver_name',
          title: 'Имя водителя',
          type: 'text',
        },
        {
          value: null,
          key: 'driver_phone',
          title: 'Телефон водителя',
          type: 'phone',
        },
        {
          value: null,
          key: 'exporter_name',
          title: 'Экспортер',
          type: 'text',
        },
        {
          value: null,
          key: 'parking_time',
          title: 'Заезд на парковку',
          type: 'text',
        },
        {
          value: null,
          key: 'submit_status',
          title: 'Статус таймс слота',
          type: 'status',
        },
        {
          value: null,
          key: 'truck_type_name',
          title: 'Тип авто',
          type: 'text',
        },
      ],
    }
  },
  computed: {
    showDeleteButton() {
      return !['arrived', 'late', 'detached'].includes(
        this.shipping.truck_status,
      )
    },
  },
  watch: {
    shipping: {
      handler(value) {
        this.preparedListOption = this.preparedListOption.map(item => {
          let newValue = { ...item, value: value[item.key] || null }

          if (item.key === 'truck_status') {
            newValue.value += ` ${value.submit_status}`
          }

          return newValue
        })
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    handleOpenModal() {
      this.isModalVisible = true
    },
    getArrRange,
  },
}
</script>

<style scoped lang="sass">
.card-container
  border: 1px solid #ebeef5
  background: white
  border-radius: 12px
  margin-bottom: 16px
  padding-bottom: 16px
  width: 100%
</style>
